import React from 'react'
import { graphql } from 'gatsby'
import { Layout, SEO } from '@renderbus/common/components'
import {
  RightsContainer,
  RightsContent,
  RightsWrapper,
  TurnTableContainer,
  TurnTableContent,
  TurnTableDescription,
  TurnTablePrizeImg,
  XGTTitle,
} from './xgt1212.atom'

import FourFreeImg from '../images/xgt1212/four-free.png'
import FreeCouponImg from '../images/xgt1212/free-coupon.png'
import HalfPriceImg from '../images/xgt1212/half-price.png'
import BenefitsImg from '../images/xgt1212/benefits-for-members.png'
import WinningTextImg from '../images/xgt1212/winning.png'
import EventTextImg from '../images/xgt1212/event-description.png'
import TurnTableImg from '../images/xgt1212/xgt-double-twelve-turntable.png'
import QRCode from '../images/xgt1212/qr-code.png'
import Number1 from '../images/xgt1212/number-1.png'
import Number2 from '../images/xgt1212/number-2.png'
import Number3 from '../images/xgt1212/number-3.png'
import WinnerList from '../molecules/xgt1212/winner-list'
import { XGTBanner } from '../molecules/banner'

const DescriptionList = [
  {
    icon: Number1,
    text: '每个用户仅有一次抽奖机会。',
  },
  {
    icon: Number2,
    text: '抽奖奖品一律不支持折换现金。',
  },
  {
    icon: Number3,
    text: '实物奖品请微信添加瑞云小助手（rayvision1），领取奖品。',
  },
]

class XGTDoubleTwelve extends React.PureComponent {
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='双12福利,充效果图会员100%中奖 - Renderbus云渲染农场'
          keywords='在线渲图,效果图渲染,线上渲图,免费渲图'
          description='Renderbus云渲染农场双12福利来袭,购买效果图会员,畅享在线渲图、免费渲图、优惠渲图，还可以参与抽奖,活动时间2020年12月09日-12月31日.'
          sharePostSlug='xgt1212.html'
        />
        <XGTBanner />
        <TurnTableContainer>
          <RightsWrapper>
            <XGTTitle title='双12福利,充效果图会员100%中奖' background={BenefitsImg}>
              效果图会员三大权益
            </XGTTitle>
            <RightsContainer>
              <RightsContent>
                <img src={FourFreeImg} alt='订阅赠免单券' />
                <h5>订阅赠免单券</h5>
                <p>
                  订阅会员即赠每月4张
                  <br />
                  免单券（不限金额）
                </p>
              </RightsContent>
              <RightsContent>
                <img src={HalfPriceImg} alt='订阅赠免单券' />
                <h5>订阅赠免单券</h5>
                <p>
                  会员期间享受5折
                  <br />
                  渲染优惠
                </p>
              </RightsContent>
              <RightsContent>
                <img src={FreeCouponImg} alt='充值赠免单券' />
                <h5>充值赠免单券</h5>
                <p>累积充值每满1000元额外赠送1张免单券（封顶赠10张/月）</p>
              </RightsContent>
            </RightsContainer>
          </RightsWrapper>
          <XGTTitle title='双12福利,充效果图会员100%中奖' background={WinningTextImg}>
            12月9-31日，
            <a
              href='https://task.renderbus.com/center/user/topUp'
              target='_blank'
              rel='noopener noreferrer'
            >
              购买/续费效果图会员
            </a>
            即可获得一次抽奖机会！100%中奖！
          </XGTTitle>
          <TurnTableContent>
            <a
              href='https://task.renderbus.com/center/user/topUp'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={TurnTableImg} alt='turntable' />
            </a>
            <WinnerList />
            <TurnTablePrizeImg
              fluid={[
                data.prizeMobileImg.childImageSharp.fluid,
                {
                  ...data.prizeImg.childImageSharp.fluid,
                  media: `(min-width: 600px)`,
                },
              ]}
            />
          </TurnTableContent>
          <XGTTitle title='双12福利,充效果图会员100%中奖' background={EventTextImg}>
            活动说明
          </XGTTitle>
          <TurnTableDescription>
            <img src={QRCode} alt='code' />
            <ul>
              {DescriptionList.map((des, index) => (
                <li key={index}>
                  <img src={des.icon} alt={index + 1} />
                  {des.text}
                </li>
              ))}
            </ul>
          </TurnTableDescription>
        </TurnTableContainer>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    bannerTitle: file(relativePath: { regex: "/xgt1212/xgt-double-twelve-title.png/" }) {
      ...fluidImage
    }
    prizeImg: file(relativePath: { regex: "/xgt1212/xgt-double-twelve-prize-list.png/" }) {
      ...fluidImage
    }
    prizeMobileImg: file(
      relativePath: { regex: "/xgt1212/xgt-double-twelve-prize-list-mobile.png/" }
    ) {
      ...fluidImage
    }
  }
`

export default XGTDoubleTwelve
