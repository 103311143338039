import { transition } from '@renderbus/common/theme'
import React from 'react'
import styled from 'styled-components'

import OthersListBg from '../../images/xgt1212/others-winner-list-bg.png'
import { RandomWinner } from './random-data'

const ListLength = 8

const WinnerListContainer = styled.div`
  margin: auto;
  padding: 65px 2px 27px 17px;
  width: 357px;
  height: 545px;
  text-align: center;
  background-image: ${`url(${OthersListBg})`};
  color: white;
  overflow: hidden;
  max-width: 100%;
`
const WinnerListBody = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`
const WinnerListRow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 57px;
  line-height: 57px;
  font-size: 16px;
  background: #090d07;
  overflow: hidden;
  transition: ${transition('top')};
  &:nth-child(even) {
    background: #061406;
  }
`

class WinnerList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      winnerList: [],
    }
    this.loopData = this.loopData.bind(this)
  }
  componentDidMount() {
    this.randomWinner = new RandomWinner(ListLength + 2)
    this.setState({ winnerList: this.randomWinner.winnerList })
    if (typeof window !== 'undefined') {
      this.timer = window.setInterval(this.loopData, 3000)
    }
  }
  componentWillUnmount() {
    typeof window !== 'undefined' && window.clearInterval(this.timer)
  }
  loopData() {
    this.randomWinner.createWinner()
    this.randomWinner.shift()
    this.setState({ winnerList: [...this.randomWinner.winnerList] })
  }
  render() {
    return (
      <WinnerListContainer>
        <WinnerListBody>
          {this.state.winnerList.map((winner, index) => (
            <WinnerListRow
              key={winner.index}
              style={{ top: `${(index - 1) * 57}px` }}
            >{`用户${winner.phone}获得了${winner.award}`}</WinnerListRow>
          ))}
        </WinnerListBody>
      </WinnerListContainer>
    )
  }
}

export default WinnerList
