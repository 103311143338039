const { PhonePrefix } = require('../../constant/phone-prefix')

const AwardList = [].concat(
  Array.from({ length: 90 }, () => '5元渲染券'),
  Array.from({ length: 6 }, () => '100元京东卡'),
  Array.from({ length: 3 }, () => '一个月效果图会员'),
  Array.from({ length: 1 }, () => '500元渲染券'),
)

export class RandomWinner {
  winnerList = []
  index = 0
  constructor(length) {
    for (let index = 0; index < length; index++) {
      this.createWinner()
    }
  }
  reset(length) {
    this.winnerList = []
    for (let index = 0; index < length; index++) {
      this.createWinner()
    }
    return this.winnerList
  }
  createWinner() {
    const phoneRamdomPrefix = PhonePrefix[Math.floor(Math.random() * PhonePrefix.length)]
    const phoneRamdomSuffix = `000${Math.floor(Math.random() * 10000) + 1}`.slice(-4)
    const awardRandom = AwardList[Math.floor(Math.random() * AwardList.length)]
    const winner = {
      phone: `${phoneRamdomPrefix}XXXX${phoneRamdomSuffix}`,
      award: awardRandom,
      index: this.index++,
    }
    this.winnerList.push(winner)
  }
  shift() {
    this.winnerList.shift()
  }
}
