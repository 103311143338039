import styled from 'styled-components'
import Img from 'gatsby-image'
import { color, Media, spacing, typography } from '@renderbus/common/theme'
import { Wrapper } from '@renderbus/common/components'

export const XGTTitle = styled.h2`
  margin: 0 0 ${spacing.base};
  height: ${typography.h1};
  line-height: ${typography.h1};
  font-size: 28px;
  color: white;
  text-align: center;
  font-weight: normal;
  background-image: ${p => `url(${p.background})`};
  background-position: center;
  background-repeat: no-repeat;
  a {
    color: #08f818;
  }
  ${Media.lessThan(Media.small)} {
    padding: 0 ${spacing.small};
    font-size: ${typography.h4};
    height: 28px;
    line-height: 28px;
    background-size: contain;
  }
`

export const RightsWrapper = styled(Wrapper)`
  margin: -307px auto 100px;
  ${Media.lessThan(Media.small)} {
    margin: 0;
    padding: 0;
  }
`

export const RightsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  width: 1000px;
  max-width: 100%;
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.large};
  }
`

export const RightsContent = styled.div`
  width: 190px;
  text-align: center;
  h5 {
    margin: ${spacing.small} 0;
    font-size: ${typography.h4};
    font-weight: normal;
    line-height: normal;
    color: white;
  }
  p {
    margin: 0;
    font-size: ${typography.textSmall};
    color: ${color.prompt};
  }
  ${Media.lessThan(Media.small)} {
    img {
      height: 35px;
    }
    h5 {
      font-size: ${typography.text};
    }
    p {
      font-size: ${typography.textThin};
    }
  }
`

export const TurnTableContainer = styled.div`
  padding: 100px 0;
  background-image: linear-gradient(to bottom, black, #1c2716);
  ${Media.lessThan(Media.small)} {
    padding: ${spacing.base} 0;
  }
`

export const TurnTableContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 1083px;
  max-width: 100%;
  margin: 60px auto 100px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.large};
    a > img {
      width: 100%;
      padding: 0 ${spacing.base};
    }
  }
`

export const TurnTablePrizeImg = styled(Img)`
  margin-top: 50px;
  height: 305px;
  width: 100%;
  ${Media.lessThan(Media.small)} {
    height: 515px;
    width: 345px;
    margin: 50px auto 0;
  }
`

export const TurnTableDescription = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  ul {
    margin: 0;
    list-style: none;
    color: white;
    font-size: ${typography.h4};
    line-height: 34px;
    img {
      object-fit: contain;
      margin-right: 5px;
      width: 30px;
      height: 14px;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: ${spacing.small};
    ul {
      padding: ${spacing.small};
      font-size: ${typography.textThin};
      line-height: 20px;
      img {
        width: auto;
        height: 10px;
      }
    }
  }
`
